var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "other-menu-edit" },
    [
      _c(
        "div",
        { staticClass: "card-define-head" },
        [
          _c("div", { staticClass: "card-define-title" }, [
            _vm._v(" " + _vm._s(_vm.title) + " "),
          ]),
          _vm.edit
            ? _c("en-icon", {
                attrs: { name: "shanchu-danchuang", size: "12px" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.deleteData.apply(null, arguments)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.edit
        ? [
            _vm.code === _vm.menuFixedTypeEnum.chat
              ? _vm._l(_vm.chatDatas, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        "border-bottom": "1px solid #E8ECF2",
                        height: "70px",
                        "padding-left": "20px",
                      },
                    },
                    [
                      _c("en-user-logo", {
                        attrs: { "user-name": item.name, size: "40" },
                      }),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "100%",
                            "padding-right": "20px",
                            "margin-left": "16px",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "justify-content": "space-between",
                                width: "100%",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    color: "#333333",
                                    "font-size": "14px",
                                  },
                                },
                                [_vm._v(_vm._s(item.name))]
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    color: "#91A1B7",
                                    "font-size": "12px",
                                  },
                                },
                                [_vm._v("05-26")]
                              ),
                            ]
                          ),
                          _c("div", { staticStyle: { "margin-top": "9px" } }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-size": "12px",
                                  color: "#636C78",
                                },
                              },
                              [_vm._v(_vm._s(item.content))]
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  )
                })
              : _vm.code === _vm.menuFixedTypeEnum.myTrip
              ? _vm._l(_vm.tripDatas, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        "border-bottom": "1px solid #E8ECF2",
                        height: "70px",
                        "padding-left": "20px",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "40px",
                            height: "40px",
                            "background-color": "#26C393",
                            display: "flex",
                            "align-items": "center",
                            "justify-content": "center",
                            "border-radius": "50%",
                          },
                        },
                        [
                          _c("en-icon", {
                            staticStyle: { color: "#FFFFFF" },
                            attrs: {
                              name: "wodehangcheng-xianxing",
                              size: "20px",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "padding-right": "20px",
                            "margin-left": "16px",
                            flex: "1",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "justify-content": "space-between",
                                width: "100%",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    color: "#333333",
                                    "font-size": "14px",
                                  },
                                },
                                [_vm._v(_vm._s(item.name))]
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    color: "#91A1B7",
                                    "font-size": "12px",
                                  },
                                },
                                [_vm._v("05-26")]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-top": "9px",
                                display: "flex",
                                "justify-content": "space-between",
                                width: "100%",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "font-size": "12px",
                                    color: "#636C78",
                                  },
                                },
                                [_vm._v(_vm._s(item.content))]
                              ),
                              _vm._m(0, true),
                            ]
                          ),
                        ]
                      ),
                    ]
                  )
                })
              : _vm.code === _vm.menuFixedTypeEnum.notice
              ? _vm._l(_vm.noticeDatas, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        "border-bottom": "1px solid #E8ECF2",
                        height: "70px",
                        "padding-left": "20px",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "40px",
                            height: "40px",
                            "background-color": "#49BEF2",
                            display: "flex",
                            "align-items": "center",
                            "justify-content": "center",
                            "border-radius": "50%",
                          },
                        },
                        [
                          _c("en-icon", {
                            staticStyle: { color: "#FFFFFF" },
                            attrs: {
                              name: "jipiaoyuding-xianxing",
                              size: "20px",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "padding-right": "20px",
                            "margin-left": "16px",
                            flex: "1",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "justify-content": "space-between",
                                width: "100%",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    color: "#333333",
                                    "font-size": "14px",
                                  },
                                },
                                [_vm._v(_vm._s(item.name))]
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    color: "#91A1B7",
                                    "font-size": "12px",
                                  },
                                },
                                [_vm._v(_vm._s(item.time))]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-top": "9px",
                                color: "#636C78",
                                "font-size": "12px",
                              },
                            },
                            [_vm._v(_vm._s(item.content))]
                          ),
                        ]
                      ),
                    ]
                  )
                })
              : _vm.code === _vm.menuFixedTypeEnum.myOrder
              ? [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        "border-bottom": "1px solid #E8ECF2",
                        height: "70px",
                        "padding-left": "20px",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "40px",
                            height: "40px",
                            "background-color": "#FFAD2C",
                            display: "flex",
                            "align-items": "center",
                            "justify-content": "center",
                            "border-radius": "50%",
                          },
                        },
                        [
                          _c("en-icon", {
                            staticStyle: { color: "#FFFFFF" },
                            attrs: {
                              name: "jiudianyuding-xianxing",
                              size: "20px",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._m(1),
                    ]
                  ),
                ]
              : _vm.code === _vm.menuFixedTypeEnum.finishedTasks
              ? _vm._l(_vm.finishedTasksDatas, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        "border-bottom": "1px solid #E8ECF2",
                        height: "70px",
                        "padding-left": "20px",
                      },
                    },
                    [
                      _c("en-user-logo", {
                        attrs: { "user-name": item.name, size: "40" },
                      }),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "padding-right": "20px",
                            "margin-left": "16px",
                            flex: "1",
                            "line-height": "14px",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "justify-content": "space-between",
                                width: "100%",
                                "margin-top": "5px",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "flex-direction": "row",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        color: "#333333",
                                        "font-size": "14px",
                                        "line-height": "14px",
                                      },
                                    },
                                    [_vm._v(_vm._s(item.name))]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        color: "#91A1B7",
                                        "font-size": "13px",
                                        "margin-left": "5px",
                                      },
                                    },
                                    [_vm._v(_vm._s(item.code))]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    color: "#91A1B7",
                                    "font-size": "12px",
                                  },
                                },
                                [_vm._v(_vm._s(item.time))]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-top": "3px",
                                display: "flex",
                                "justify-content": "space-between",
                                width: "100%",
                                "align-items": "end",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "font-size": "12px",
                                    color: "#636C78",
                                    "line-height": "23px",
                                  },
                                },
                                [_vm._v(_vm._s(item.content))]
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    position: "relative",
                                    height: "23px",
                                    width: "62px",
                                    display: "flex",
                                    "align-items": "center",
                                    "justify-content": "center",
                                  },
                                },
                                [
                                  _c("div", {
                                    staticStyle: {
                                      position: "absolute",
                                      "border-radius": "4px",
                                      opacity: "0.1",
                                      width: "100%",
                                      height: "100%",
                                    },
                                    style:
                                      "background-color:" + item.statusColor,
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: { "font-size": "12px" },
                                      style: "color:" + item.statusColor,
                                    },
                                    [_vm._v(_vm._s(item.status))]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                })
              : _vm.code === _vm.menuFixedTypeEnum.gtasks
              ? _vm._l(_vm.gTasksDatas, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        "border-bottom": "1px solid #E8ECF2",
                        height: "70px",
                        "padding-left": "20px",
                      },
                    },
                    [
                      _c("en-user-logo", {
                        attrs: { "user-name": item.name, size: "40" },
                      }),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "padding-right": "20px",
                            "margin-left": "16px",
                            flex: "1",
                            "line-height": "14px",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "justify-content": "space-between",
                                width: "100%",
                                "margin-top": "5px",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    color: "#333333",
                                    "font-size": "14px",
                                    "line-height": "14px",
                                  },
                                },
                                [_vm._v(_vm._s(item.name))]
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    color: "#91A1B7",
                                    "font-size": "12px",
                                  },
                                },
                                [_vm._v(_vm._s(item.time))]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-top": "3px",
                                display: "flex",
                                "justify-content": "space-between",
                                width: "100%",
                                "align-items": "end",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "font-size": "12px",
                                    color: "#636C78",
                                    "line-height": "23px",
                                  },
                                },
                                [_vm._v(_vm._s(item.content))]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                })
              : _vm.code === _vm.menuFixedTypeEnum.myBusiness
              ? _vm._l(_vm.myBusinessDatas, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        "border-bottom": "1px solid #E8ECF2",
                        height: "70px",
                        "padding-left": "20px",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "40px",
                            height: "40px",
                            "background-color": "#FFAD2C",
                            display: "flex",
                            "align-items": "center",
                            "justify-content": "center",
                            "border-radius": "50%",
                          },
                        },
                        [
                          _c("en-icon", {
                            staticStyle: { color: "#FFFFFF" },
                            attrs: { name: "bus-board-icon7", size: "20px" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "padding-right": "20px",
                            "margin-left": "16px",
                            flex: "1",
                            "line-height": "14px",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "justify-content": "space-between",
                                width: "100%",
                                "margin-top": "5px",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "flex-direction": "row",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        color: "#333333",
                                        "font-size": "14px",
                                        "line-height": "14px",
                                      },
                                    },
                                    [_vm._v(_vm._s(item.code))]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    color: "#91A1B7",
                                    "font-size": "12px",
                                  },
                                },
                                [_vm._v(_vm._s(item.time))]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-top": "3px",
                                display: "flex",
                                "justify-content": "space-between",
                                width: "100%",
                                "align-items": "end",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "font-size": "12px",
                                    color: "#636C78",
                                    "line-height": "23px",
                                  },
                                },
                                [_vm._v(_vm._s(item.content))]
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    position: "relative",
                                    height: "23px",
                                    width: "62px",
                                    display: "flex",
                                    "align-items": "center",
                                    "justify-content": "center",
                                  },
                                },
                                [
                                  _c("div", {
                                    staticStyle: {
                                      position: "absolute",
                                      "border-radius": "4px",
                                      opacity: "0.1",
                                      width: "100%",
                                      height: "100%",
                                    },
                                    style:
                                      "background-color:" + item.statusColor,
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: { "font-size": "12px" },
                                      style: "color:" + item.statusColor,
                                    },
                                    [_vm._v(_vm._s(item.status))]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  )
                })
              : _vm.code === _vm.menuFixedTypeEnum.documentManagement
              ? [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%", height: "100%" },
                      attrs: { border: "", data: _vm.documentDatas },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "name", label: "文档名称" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center",
                                      height: "100%",
                                      "flex-wrap": "nowrap",
                                    },
                                  },
                                  [
                                    _c("en-icon", {
                                      staticStyle: { color: "#3e90fe" },
                                      attrs: {
                                        name: "fujian-tongyong",
                                        size: "16",
                                      },
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "margin-left": "8px",
                                          color: "#636C78",
                                          "font-size": "12px",
                                        },
                                      },
                                      [_vm._v(_vm._s(scope.row.name))]
                                    ),
                                  ],
                                  1
                                )
                              },
                            },
                          ],
                          null,
                          false,
                          1523278933
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "发布者" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center",
                                      height: "100%",
                                      "flex-wrap": "nowrap",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticStyle: { "font-size": "12px" } },
                                      [
                                        _vm._v(
                                          _vm._s(scope.row.submitPersonInfo)
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              },
                            },
                          ],
                          null,
                          false,
                          2320669282
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "submitTime", label: "发布时间" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center",
                                      height: "100%",
                                      "flex-wrap": "nowrap",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticStyle: { "font-size": "12px" } },
                                      [_vm._v(_vm._s(scope.row.submitTime))]
                                    ),
                                  ]
                                )
                              },
                            },
                          ],
                          null,
                          false,
                          552727916
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "附件" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center",
                                      height: "100%",
                                      "flex-wrap": "nowrap",
                                    },
                                  },
                                  [
                                    _c("en-icon", {
                                      staticStyle: { color: "#3e90fe" },
                                      attrs: {
                                        name: "fujianhetupian-kongjian",
                                        size: "16",
                                      },
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "margin-left": "8px",
                                          color: "#3e90fe",
                                          "font-size": "12px",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "(" + _vm._s(scope.row.count) + ")"
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              },
                            },
                          ],
                          null,
                          false,
                          1053751269
                        ),
                      }),
                    ],
                    1
                  ),
                ]
              : _vm.code === _vm.menuFixedTypeEnum.wallet
              ? [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { border: "", data: _vm.walletDatas },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "index", label: "序号", width: "50" },
                      }),
                      _c("el-table-column", {
                        attrs: { label: "发票代码" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    { staticStyle: { "font-size": "12px" } },
                                    [_vm._v(_vm._s(scope.row.code))]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3544755663
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "发票号码" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    { staticStyle: { "font-size": "12px" } },
                                    [_vm._v(_vm._s(scope.row.no))]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          621906787
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "发票项目" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    { staticStyle: { "font-size": "12px" } },
                                    [_vm._v(_vm._s(scope.row.name))]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2318574213
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "发票类型" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    { staticStyle: { "font-size": "12px" } },
                                    [_vm._v(_vm._s(scope.row.type))]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2512471546
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "不含税金额（元）" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    { staticStyle: { "font-size": "12px" } },
                                    [_vm._v(_vm._s(scope.row.money))]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          319216018
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "价税合计" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    { staticStyle: { "font-size": "12px" } },
                                    [_vm._v(_vm._s(scope.row.totalMoney))]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          368419408
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "报销状态" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        position: "relative",
                                        height: "23px",
                                        width: "62px",
                                        display: "flex",
                                        "align-items": "center",
                                        "justify-content": "center",
                                      },
                                    },
                                    [
                                      _c("div", {
                                        staticStyle: {
                                          position: "absolute",
                                          "border-radius": "4px",
                                          opacity: "0.1",
                                          width: "100%",
                                          height: "100%",
                                          "background-color": "#3e90fe",
                                        },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "font-size": "12px",
                                            color: "#3e90fe",
                                          },
                                        },
                                        [_vm._v(_vm._s(scope.row.status))]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2277766033
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "查验状态" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        position: "relative",
                                        height: "23px",
                                        width: "62px",
                                        display: "flex",
                                        "align-items": "center",
                                        "justify-content": "center",
                                      },
                                    },
                                    [
                                      _c("div", {
                                        staticStyle: {
                                          position: "absolute",
                                          "border-radius": "4px",
                                          opacity: "0.1",
                                          width: "100%",
                                          height: "100%",
                                          "background-color": "#3e90fe",
                                        },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "font-size": "12px",
                                            color: "#3e90fe",
                                          },
                                        },
                                        [_vm._v(_vm._s(scope.row.checkStatus))]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3643430135
                        ),
                      }),
                    ],
                    1
                  ),
                ]
              : _vm.isBusinessList
              ? [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { border: "", data: _vm.businessListDatas },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "index", label: "序号", width: "50" },
                      }),
                      _c("el-table-column", {
                        attrs: { label: "项目名称" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    { staticStyle: { "font-size": "12px" } },
                                    [_vm._v(_vm._s(scope.row.name1))]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1703581076
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "客户名称" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    { staticStyle: { "font-size": "12px" } },
                                    [_vm._v(_vm._s(scope.row.name2))]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3748794487
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "所属部门" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    { staticStyle: { "font-size": "12px" } },
                                    [_vm._v(_vm._s(scope.row.name3))]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1563516502
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "交易金额" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    { staticStyle: { "font-size": "12px" } },
                                    [_vm._v(_vm._s(scope.row.money))]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          319216018
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "交易日期" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    { staticStyle: { "font-size": "12px" } },
                                    [_vm._v(_vm._s(scope.row.time))]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          269924183
                        ),
                      }),
                    ],
                    1
                  ),
                ]
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticStyle: {
          position: "relative",
          height: "23px",
          width: "62px",
          display: "flex",
          "align-items": "center",
          "justify-content": "center",
        },
      },
      [
        _c("div", {
          staticStyle: {
            position: "absolute",
            "border-radius": "4px",
            opacity: "0.1",
            "background-color": "#FFAD2C",
            width: "100%",
            height: "100%",
          },
        }),
        _c("div", { staticStyle: { "font-size": "12px", color: "#FFAD2C" } }, [
          _vm._v("审批中"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticStyle: {
          "padding-right": "20px",
          "margin-left": "16px",
          flex: "1",
        },
      },
      [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "justify-content": "space-between",
              width: "100%",
            },
          },
          [
            _c(
              "div",
              { staticStyle: { color: "#333333", "font-size": "14px" } },
              [_vm._v("7月18日 → 7月19日 如家快捷酒店")]
            ),
            _c(
              "div",
              { staticStyle: { color: "#91A1B7", "font-size": "12px" } },
              [_vm._v("05-26")]
            ),
          ]
        ),
        _c(
          "div",
          {
            staticStyle: {
              "margin-top": "9px",
              display: "flex",
              "justify-content": "space-between",
              width: "100%",
            },
          },
          [
            _c(
              "div",
              { staticStyle: { "font-size": "12px", color: "#636C78" } },
              [_vm._v("￥200.00")]
            ),
            _c(
              "div",
              {
                staticStyle: {
                  position: "relative",
                  height: "23px",
                  width: "62px",
                  display: "flex",
                  "align-items": "center",
                  "justify-content": "center",
                },
              },
              [
                _c("div", {
                  staticStyle: {
                    position: "absolute",
                    "border-radius": "4px",
                    opacity: "0.1",
                    "background-color": "#FFAD2C",
                    width: "100%",
                    height: "100%",
                  },
                }),
                _c(
                  "div",
                  { staticStyle: { "font-size": "12px", color: "#FFAD2C" } },
                  [_vm._v("预订中")]
                ),
              ]
            ),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }