<template>
  <div class="other-menu-edit">
    <div class="card-define-head">
      <div class="card-define-title">
        {{ title }}
      </div>
      <en-icon
        v-if="edit"
        name="shanchu-danchuang"
        size="12px"
        @click.native="deleteData"
      >
      </en-icon>
    </div>
    <template v-if="edit">
      <template v-if="code === menuFixedTypeEnum.chat">
        <div
          style="display: flex;align-items: center;border-bottom: 1px solid #E8ECF2;height: 70px;padding-left: 20px;"
          v-for="(item, index) in chatDatas"
          :key="index"
        >
          <en-user-logo
            :user-name="item.name"
            size="40"
          ></en-user-logo>
          <div style="width: 100%;padding-right: 20px;margin-left: 16px">
            <div style="display: flex;justify-content: space-between;width: 100%">
              <div style="color:#333333;font-size:14px;">{{ item.name }}</div>
              <div style="color:#91A1B7;font-size:12px">05-26</div>
            </div>
            <div style="margin-top:9px">
              <div style="font-size:12px;color:#636C78">{{ item.content }}</div>
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="code === menuFixedTypeEnum.myTrip">
        <div
          style="display: flex;align-items: center;border-bottom: 1px solid #E8ECF2;height: 70px;padding-left: 20px;"
          v-for="(item, index) in tripDatas"
          :key="index"
        >
          <div style="width: 40px;height: 40px;background-color: #26C393;display: flex;align-items: center;justify-content: center;border-radius: 50%">
            <en-icon name="wodehangcheng-xianxing" size="20px" style="color:#FFFFFF"></en-icon>
          </div>
          <div style="padding-right: 20px;margin-left: 16px;flex: 1">
            <div style="display: flex;justify-content: space-between;width: 100%">
              <div style="color:#333333;font-size:14px;">{{ item.name }}</div>
              <div style="color:#91A1B7;font-size:12px">05-26</div>
            </div>
            <div style="margin-top:9px;display: flex;justify-content: space-between;width: 100%">
              <div style="font-size:12px;color:#636C78">{{ item.content }}</div>
              <div style="position: relative;height: 23px;width:62px;display: flex;align-items: center;justify-content: center">
                <div style="position: absolute;border-radius:4px;opacity: 0.1;background-color: #FFAD2C;width: 100%;height: 100%"></div>
                <div style="font-size:12px;color:#FFAD2C">审批中</div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="code === menuFixedTypeEnum.notice">
        <div
          style="display: flex;align-items: center;border-bottom: 1px solid #E8ECF2;height: 70px;padding-left: 20px;"
          v-for="(item, index) in noticeDatas"
          :key="index"
        >
          <div style="width: 40px;height: 40px;background-color: #49BEF2;display: flex;align-items: center;justify-content: center;border-radius: 50%">
            <en-icon name="jipiaoyuding-xianxing" size="20px" style="color:#FFFFFF"></en-icon>
          </div>
          <div style="padding-right: 20px;margin-left: 16px;flex: 1">
            <div style="display: flex;justify-content: space-between;width: 100%">
              <div style="color:#333333;font-size:14px;">{{ item.name }}</div>
              <div style="color:#91A1B7;font-size:12px">{{ item.time }}</div>
            </div>
            <div style="margin-top:9px;color: #636C78;font-size: 12px">{{ item.content }}</div>
          </div>
        </div>
      </template>
      <template v-else-if="code === menuFixedTypeEnum.myOrder">
        <div
          style="display: flex;align-items: center;border-bottom: 1px solid #E8ECF2;height: 70px;padding-left: 20px;"
        >
          <div style="width: 40px;height: 40px;background-color: #FFAD2C;display: flex;align-items: center;justify-content: center;border-radius: 50%">
            <en-icon name="jiudianyuding-xianxing" size="20px" style="color:#FFFFFF"></en-icon>
          </div>
          <div style="padding-right: 20px;margin-left: 16px;flex: 1">
            <div style="display: flex;justify-content: space-between;width: 100%">
              <div style="color:#333333;font-size:14px;">7月18日 → 7月19日 如家快捷酒店</div>
              <div style="color:#91A1B7;font-size:12px">05-26</div>
            </div>
            <div style="margin-top:9px;display: flex;justify-content: space-between;width: 100%">
              <div style="font-size:12px;color:#636C78">￥200.00</div>
              <div style="position: relative;height: 23px;width:62px;display: flex;align-items: center;justify-content: center">
                <div style="position: absolute;border-radius:4px;opacity: 0.1;background-color: #FFAD2C;width: 100%;height: 100%"></div>
                <div style="font-size:12px;color:#FFAD2C">预订中</div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="code === menuFixedTypeEnum.finishedTasks">
        <div
          style="display: flex;align-items: center;border-bottom: 1px solid #E8ECF2;height: 70px;padding-left: 20px;"
          v-for="(item, index) in finishedTasksDatas"
          :key="index"
        >
          <en-user-logo
            :user-name="item.name"
            size="40"
          ></en-user-logo>
          <div style="padding-right: 20px;margin-left: 16px;flex: 1;line-height: 14px">
            <div style="display: flex;justify-content: space-between;width: 100%;margin-top: 5px">
              <div style="display: flex;flex-direction: row">
                <div style="color:#333333;font-size:14px;line-height: 14px;">{{ item.name }}</div>
                <div style="color:#91A1B7;font-size: 13px;margin-left: 5px;">{{ item.code }}</div>
              </div>
              <div style="color:#91A1B7;font-size:12px">{{ item.time }}</div>
            </div>
            <div style="margin-top:3px;display: flex;justify-content: space-between;width: 100%;align-items: end">
              <div style="font-size:12px;color:#636C78;line-height: 23px">{{ item.content }}</div>
              <div style="position: relative;height: 23px;width:62px;display: flex;align-items: center;justify-content: center">
                <div style="position: absolute;border-radius:4px;opacity: 0.1;width: 100%;height: 100%" :style="'background-color:'+item.statusColor"></div>
                <div style="font-size:12px;" :style="'color:'+item.statusColor">{{ item.status }}</div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="code === menuFixedTypeEnum.gtasks">
        <div
          style="display: flex;align-items: center;border-bottom: 1px solid #E8ECF2;height: 70px;padding-left: 20px;"
          v-for="(item, index) in gTasksDatas"
          :key="index"
        >
          <en-user-logo
            :user-name="item.name"
            size="40"
          ></en-user-logo>
          <div style="padding-right: 20px;margin-left: 16px;flex: 1;line-height: 14px">
            <div style="display: flex;justify-content: space-between;width: 100%;margin-top: 5px">
              <div style="color:#333333;font-size:14px;line-height: 14px;">{{ item.name }}</div>
              <div style="color:#91A1B7;font-size:12px">{{ item.time }}</div>
            </div>
            <div style="margin-top:3px;display: flex;justify-content: space-between;width: 100%;align-items: end">
              <div style="font-size:12px;color:#636C78;line-height: 23px">{{ item.content }}</div>
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="code === menuFixedTypeEnum.myBusiness">
        <div
          style="display: flex;align-items: center;border-bottom: 1px solid #E8ECF2;height: 70px;padding-left: 20px;"
          v-for="(item, index) in myBusinessDatas"
          :key="index"
        >
          <div style="width: 40px;height: 40px;background-color: #FFAD2C;display: flex;align-items: center;justify-content: center;border-radius: 50%">
            <en-icon name="bus-board-icon7" size="20px" style="color:#FFFFFF"></en-icon>
          </div>
          <div style="padding-right: 20px;margin-left: 16px;flex: 1;line-height: 14px">
            <div style="display: flex;justify-content: space-between;width: 100%;margin-top: 5px">
              <div style="display: flex;flex-direction: row">
                <div style="color:#333333;font-size:14px;line-height: 14px;">{{ item.code }}</div>
              </div>
              <div style="color:#91A1B7;font-size:12px">{{ item.time }}</div>
            </div>
            <div style="margin-top:3px;display: flex;justify-content: space-between;width: 100%;align-items: end">
              <div style="font-size:12px;color:#636C78;line-height: 23px">{{ item.content }}</div>
              <div style="position: relative;height: 23px;width:62px;display: flex;align-items: center;justify-content: center">
                <div style="position: absolute;border-radius:4px;opacity: 0.1;width: 100%;height: 100%" :style="'background-color:'+item.statusColor"></div>
                <div style="font-size:12px;" :style="'color:'+item.statusColor">{{ item.status }}</div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="code === menuFixedTypeEnum.documentManagement">
        <el-table border style="width: 100%;height: 100%" :data="documentDatas">
          <el-table-column
            prop="name"
            label="文档名称">
            <div slot-scope="scope" style="display: flex;align-items: center;height: 100%;flex-wrap: nowrap">
              <en-icon name="fujian-tongyong" size="16" style="color: #3e90fe">
              </en-icon>
              <div style="margin-left: 8px;color: #636C78;font-size: 12px">{{ scope.row.name }}</div>
            </div>
          </el-table-column>
          <el-table-column
            label="发布者">
            <div slot-scope="scope" style="display: flex;align-items: center;height: 100%;flex-wrap: nowrap">
              <div style="font-size: 12px">{{ scope.row.submitPersonInfo }}</div>
            </div>
          </el-table-column>
          <el-table-column
            prop="submitTime"
            label="发布时间">
            <div slot-scope="scope" style="display: flex;align-items: center;height: 100%;flex-wrap: nowrap">
              <div style="font-size: 12px">{{ scope.row.submitTime }}</div>
            </div>
          </el-table-column>
          <el-table-column
            label="附件">
            <div slot-scope="scope" style="display: flex;align-items: center;height: 100%;flex-wrap: nowrap;">
              <en-icon name="fujianhetupian-kongjian" size="16" style="color: #3e90fe">
              </en-icon>
              <div style="margin-left: 8px;color: #3e90fe;font-size: 12px">({{ scope.row.count }})</div>
            </div>
          </el-table-column>
        </el-table>
      </template>
      <template v-else-if="code === menuFixedTypeEnum.wallet">
        <el-table border style="width: 100%" :data="walletDatas">
          <el-table-column
            type="index"
            label="序号"
            width="50">
          </el-table-column>
          <el-table-column
            label="发票代码">
            <template slot-scope="scope">
              <div style="font-size: 12px">{{ scope.row.code }}</div>
            </template>
          </el-table-column>
          <el-table-column
            label="发票号码">
            <template slot-scope="scope">
              <div style="font-size: 12px">{{ scope.row.no }}</div>
            </template>
          </el-table-column>
          <el-table-column
            label="发票项目">
            <template slot-scope="scope">
              <div style="font-size: 12px">{{ scope.row.name }}</div>
            </template>
          </el-table-column>
          <el-table-column
            label="发票类型">
            <template slot-scope="scope">
              <div style="font-size: 12px">{{ scope.row.type }}</div>
            </template>
          </el-table-column>
          <el-table-column
            label="不含税金额（元）">
            <template slot-scope="scope">
              <div style="font-size: 12px">{{ scope.row.money }}</div>
            </template>
          </el-table-column>
          <el-table-column
            label="价税合计">
            <template slot-scope="scope">
              <div style="font-size: 12px">{{ scope.row.totalMoney }}</div>
            </template>
          </el-table-column>
          <el-table-column
            label="报销状态">
            <template slot-scope="scope">
              <div style="position: relative;height: 23px;width:62px;display: flex;align-items: center;justify-content: center">
                <div style="position: absolute;border-radius:4px;opacity: 0.1;width: 100%;height: 100%;background-color:#3e90fe"></div>
                <div style="font-size:12px;color:#3e90fe">{{ scope.row.status }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="查验状态">
            <template slot-scope="scope">
              <div style="position: relative;height: 23px;width:62px;display: flex;align-items: center;justify-content: center">
                <div style="position: absolute;border-radius:4px;opacity: 0.1;width: 100%;height: 100%;background-color:#3e90fe"></div>
                <div style="font-size:12px;color:#3e90fe">{{ scope.row.checkStatus }}</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <template v-else-if="isBusinessList">
        <el-table border style="width: 100%" :data="businessListDatas">
          <el-table-column
            type="index"
            label="序号"
            width="50">
          </el-table-column>
          <el-table-column
            label="项目名称">
            <template slot-scope="scope">
              <div style="font-size: 12px">{{ scope.row.name1 }}</div>
            </template>
          </el-table-column>
          <el-table-column
            label="客户名称">
            <template slot-scope="scope">
              <div style="font-size: 12px">{{ scope.row.name2 }}</div>
            </template>
          </el-table-column>
          <el-table-column
            label="所属部门">
            <template slot-scope="scope">
              <div style="font-size: 12px">{{ scope.row.name3 }}</div>
            </template>
          </el-table-column>
          <el-table-column
            label="交易金额">
            <template slot-scope="scope">
              <div style="font-size: 12px">{{ scope.row.money }}</div>
            </template>
          </el-table-column>
          <el-table-column
            label="交易日期">
            <template slot-scope="scope">
              <div style="font-size: 12px">{{ scope.row.time }}</div>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </template>
  </div>
</template>

<script>

import { menuFixedTypeEnum, menuDataTypeEnum } from "@/api/homeMenu/handleMenu";

export default {
  name: "other-menu-edit",
  props: {
    edit: {
      type: Boolean
    },
    menuData: {
      type: Object
    }
  },
  data() {
    return {
      menuFixedTypeEnum,
      chatDatas: [{ name: "群聊", content: "群聊" }, { name: "宋晓红", content: "我给你分派了个任务，你看一下" }],
      tripDatas: [{ name: "2019-10-1/2019-10-11", content: "行程事由内容" }, { name: "2019-09-23/2019-09-25", content: "行程事由内容" }],
      finishedTasksDatas: [
        {
          name: "张三", code: "CQ2018080788", time: "05-10", content: "请假申请单", status: "审批中", statusColor: "#FFAD2C"
        },
        {
          name: "刘贝", code: "CQ2018080789", time: "05-10", content: "商旅申请单，办公用品200.00元", status: "已结束", statusColor: "#636C78"
        }
      ],
      gTasksDatas: [
        {
          name: "张三", code: "CQ2018080788", time: "05-10", content: "费用申请单，差旅费500元，请审批！"
        },
        {
          name: "刘贝", code: "CQ2018080789", time: "05-10", content: "商旅申请单，办公用品200.00元"
        }
      ],
      noticeDatas: [
        {
          name: "机票预订", time: "05-10", content: "您预订的北京-上海的机票正在出票中！"
        }
      ],
      myBusinessDatas: [
        {
          name: "张三", code: "CQ2018080788", time: "05-10", content: "请假申请单", status: "审批中", statusColor: "#FFAD2C"
        },
        {
          name: "刘贝", code: "CQ2018080789", time: "05-10", content: "报销单", status: "已结束", statusColor: "#636C78"
        }
      ],
      documentDatas: [
        {
          name: "请假规则", submitPersonInfo: "人事部", submitTime: "2019-03-15 11:55", count: 1
        },
        {
          name: "文档名称", submitPersonInfo: "人事部", submitTime: "2019-03-15 11:55", count: 2
        },
        {
          name: "文档名称", submitPersonInfo: "人事部", submitTime: "2019-03-15 11:55", count: 2
        }
      ],
      walletDatas: [
        {
          code: "FP0611120", no: "19890622", name: "发票内容", type: "增值税务普通发票纸质", money: "35,00.00", totalMoney: "100.00", status: "未报销", chaeckStauts: "未查验"
        },
        {
          code: "FP0611120", no: "19890622", name: "发票内容", type: "增值税务普通发票纸质", money: "35,00.00", totalMoney: "100.00", status: "未报销", chaeckStauts: "未查验"
        }
      ],
      businessListDatas: [
        {
          name1: "项目名称", name2: "客户公司", name3: "财务部", money: "100", time: "2019-03-15 11:55"
        },
        {
          name1: "项目名称", name2: "客户公司2", name3: "财务部", money: "1000", time: "2019-03-15 11:55"
        }
      ]
    };
  },
  computed: {
    title() {
      return this.menuData?.name;
    },
    dataType() {
      return this.menuData?.dataType;
    },
    code() {
      return this.menuData?.code;
    },
    isBusinessList() {
      return this.code === menuFixedTypeEnum.newAddArticle
        || this.code === menuFixedTypeEnum.articleApply
        || this.dataType === menuDataTypeEnum.business
        || this.dataType === menuDataTypeEnum.templateList;
    }
  },
  methods: {
    deleteData() {
      this.$emit("deleteData");
    }
  }
};
</script>

<style lang="scss" scoped>

.other-menu-edit {
  height: 100%;
  .card-define-head {
    width: 100%;
    line-height: 40px;
    border-bottom: 1px solid #e8ecf2;
    display: flex;
    justify-content: space-between;
    padding:5px 15px;
    position: relative;

    .card-define-title {
      font-size: 14px;
      color: #333;
      font-weight: bold;
    }
    .en-icon {
      cursor: pointer;
      color: #a9b5c6;
      position: absolute;
      top:13px;
      right: 20px;
    }
  }
}

</style>
